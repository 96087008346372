import React, { Component } from 'react';
import LeftMenu from '../LeftMenu';
import "./DashBoardOverview.css";
import Api from "../../lib/Api";
import axios from "axios";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryStack, VictoryGroup } from 'victory';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faDotCircle, faCircle} from "@fortawesome/free-solid-svg-icons";

class DashboardOverview extends Component {
    state = { 
        totalToko: 0,
        totalPengguna: 0,
        totalSalesProduct: 0,
        topEtalase: [],
        topEtalaseSummaryLainnya: 0,
        tickFormatTokoTerlaris: [],
        tickValueTokoTerlaris: [],
        dataTokoTerlaris: [],
        tickFormatTopTransaksi: [],
        tickValueTopTransaksi: [],
        dataTopTransaksi: [],
     }

    loadTopStatistics = () => {
        const params = {
            // filter:{store_id: "possax" },
            // sort:{nama: 1},
            // limit:100
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .get(Api.topStatistics())
          .then((response) => {
            console.log("TOPSTATISTICS", response.data);
            const responseData = response.data;
            
            var totalToko = responseData.countStore;
            var totalPengguna = responseData.countUser;
            var totalSalesProduct = 0;
            var topEtalaseSummaryLainnya = 0;

            responseData.countSalesItem.forEach(sales => {
                totalSalesProduct += sales.count;
            });

            for(var i=0; i<responseData.topEtalase.length; i++){
                if(i > 2){
                    topEtalaseSummaryLainnya += responseData.topEtalase[i].count;
                }
            }

            var dataToko = [];
            var i = 0;
            responseData.topTransaction.forEach(element => {
                i += 1;
                // console.log("STORE ",i, store);
                if(i <= 5){
                    dataToko.push(
                        {
                            tickValue: i,
                            tickFormat: element.storess[0].nama_toko,
                            data: [
                                {quarter: i, earnings: element.count},
                            ]
                        }
                    );
                }
                
            });

            var tickFormat = [];
            var tickValue = [];
            i = 0;
            dataToko.map((data) => {
                tickFormat.push(data.tickFormat.replace(" ", "\n"));
                tickValue.push(++i);
            });

            // this.setState({dataTokoTerlaris: dataToko, tickValue: tickValue, tickFormat: tickFormat}, ()=>{
            //     console.log("DATA_TOKO",this.state.dataTokoTerlaris);
            // });

            console.log("TOPETALASE : ", responseData.topEtalase)
            this.setState({
                totalToko: totalToko, 
                totalPengguna: totalPengguna, 
                totalSalesProduct: totalSalesProduct, 
                topEtalase: responseData.topEtalase,
                topEtalaseSummaryLainnya: topEtalaseSummaryLainnya,
                dataTokoTerlaris: dataToko, 
                tickValue: tickValue, 
                tickFormat: tickFormat
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadTopStores = () => {
        const params = {
            match:{},
            sort:{_id: -1},
            limit:5
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
        .post(
            Api.topStores(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("TOPSTORES", response.data);
            const responseData = response.data;
            
            var dataToko = [];
            var i = 0;
            responseData.data.forEach(store => {
                i += 1;
                console.log("STORE ",i, store);
                dataToko.push(
                    {
                        tickValue: i,
                        tickFormat: store.nama_toko,
                        data: [
                            {quarter: i, earnings: store.totalTransaksi},
                        ]
                    }
                );
            });

            console.log("VAR_DATA_TOKO : ", dataToko);

            var tickFormat = [];
            var tickValue = [];
            i = 0;
            dataToko.map((data) => {
                tickFormat.push(data.tickFormat.replace(" ", "\n"));
                tickValue.push(++i);
            });

            this.setState({dataTokoTerlaris: dataToko, tickValue: tickValue, tickFormat: tickFormat}, ()=>{
                console.log("DATA_TOKO",this.state.dataTokoTerlaris);
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadTopTransaksi = () => {
        const params = {
            match:{},
            sort:{totalTransaksi: -1},
            limit:0
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
        .post(
            Api.topStores(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("TOPSTORESI", response.data);
            const responseData = response.data;
            
            var dataToko = [];
            var i = 0;
            responseData.data.forEach(store => {
                i += 1;
                console.log("STORE ",i, store);
                if(i <= 5){
                    dataToko.push(
                        {
                            tickValue: i,
                            tickFormat: store.nama_toko,
                            data: [
                                {quarter: i, earnings: store.totalTransaksi},
                            ]
                        }
                    );
                }

                
            });

            console.log("VAR_DATA_TOKO : ", dataToko);

            var tickFormat = [];
            var tickValue = [];
            i = 0;
            dataToko.map((data) => {
                tickFormat.push(data.tickFormat.replace(" ", "\n"));
                tickValue.push(++i);
            });

            this.setState({dataTopTransaksi: dataToko, tickValueTopTransaksi: tickValue, tickFormatTopTransaksi: tickFormat}, ()=>{
                console.log("DATA_TOKO_TOP",this.state.dataTopTransaksi);
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    componentDidMount() {
        this.loadTopStatistics();
        // this.loadTopStores();
        this.loadTopTransaksi();
    }

    render() { 
        console.log("RENDER ", this.state.dataTokoTerlaris);
        return ( 

            <div className="container-fluid no-padding">
                <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-2">
                            <h2 className="mt-4">Overview</h2>
                        </div>
                        <div className="col-10">
                            <p className="mt-4">
                            Sebagai halaman awal untuk memonitoring<br />seluruh aktifitas di aplikasi<br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid dashboard-content">
                    
                    <div className="container rounded-border solid-border">
                        <div className="row">
                            <div className="col-2 top-resume-item text-center">
                                <small className="font-weight-lighter">Total Toko</small>
                                <h3 style={{marginTop: 15}}>{this.state.totalToko}</h3>
                            </div>
                            <div className="col-2 top-resume-item text-center">
                                <small className="font-weight-lighter">Jumlah Pengguna</small>
                                <br />
                                <h3 style={{marginTop: 15}}>{this.state.totalPengguna}</h3>
                            </div>
                            <div className="col-2 top-resume-item text-center">
                                <small className="font-weight-lighter">Penjualan Produk</small>
                                <br />
                                <h3 style={{marginTop: 15}}>{this.state.totalSalesProduct}</h3>
                            </div>
                            <div className="col-6">
                                <small className="font-weight-lighter">Top Etalase Produk</small>
                                <div className="row">
                                    <div className="col-3">
                                        <small style={{textTransform: "capitalize"}}><FontAwesomeIcon icon={faCircle} style={{fontSize: 9}}/> {this.state.topEtalase.length > 0 ? this.state.topEtalase[0]._id : ""}</small>
                                        <h3>{this.state.topEtalase.length > 0 ? this.state.topEtalase[0].count : ""}</h3>
                                    </div>
                                    <div className="col-3">
                                        <small style={{textTransform: "capitalize"}}><FontAwesomeIcon icon={faCircle} style={{fontSize: 9}} /> {this.state.topEtalase.length > 1 ? this.state.topEtalase[1]._id : ""}</small>
                                        <h3>{this.state.topEtalase.length > 1 ? this.state.topEtalase[1].count : ""}</h3>
                                    </div>
                                    <div className="col-3">
                                        <small style={{textTransform: "capitalize"}}><FontAwesomeIcon icon={faCircle} style={{fontSize: 9}} /> {this.state.topEtalase.length > 3 ? this.state.topEtalase[3]._id : ""}</small>
                                        <h3>{this.state.topEtalase.length > 2 ? this.state.topEtalase[2].count : ""}</h3>
                                    </div>
                                    <div className="col-3">
                                        <small style={{textTransform: "capitalize"}}><FontAwesomeIcon icon={faCircle} style={{fontSize: 9}} /> Lainnya</small>
                                        <h3>{this.state.topEtalase.length > 2 ? this.state.topEtalaseSummaryLainnya : ""}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-1"></div> */}
                            <div className="col-6 rounded-border solid-border" style={{padding:10}}>
                                <h5>Toko Terlaris</h5>
                                <hr />
                                {this.state.dataTokoTerlaris.length <= 0 ? 
                                    <div></div> : 
                                    <div className="solid-border">
                                        <VictoryChart
                                            domainPadding={15}
                                            theme={VictoryTheme.material}
                                        >
                                            
                                            <VictoryAxis
                                                tickValues={this.state.tickValue}
                                                tickFormat={this.state.tickFormat}
                                            />
                                            <VictoryAxis
                                                dependentAxis
                                                tickFormat={(x) => (`${x / 1000}`)}
                                            />
                                            {/* <VictoryGroup horizontal> */}
                                            <VictoryStack>
                                                
                                                {
                                                    this.state.dataTokoTerlaris.map((data) => (
                                                        <VictoryBar
                                                            data={data.data}
                                                            x="quarter"
                                                            y="earnings"
                                                        />
                                                    ))
                                                }
                                            
                                                
                                            </VictoryStack>
                                            {/* </VictoryGroup> */}
                                        </VictoryChart>
                                    </div>
                                    
                                }
                                
                            </div>
                            <div className="col-1"></div>
                            <div className="col-5 rounded-border solid-border" style={{padding:10}}>
                                <h5>Transaksi Terbanyak</h5>
                                <hr />
                                {this.state.dataTopTransaksi.length <= 0 ? 
                                    <div></div> : 
                                    <VictoryChart
                                        domainPadding={15}
                                        theme={VictoryTheme.material}
                                    >
                                        <VictoryAxis
                                            tickValues={this.state.tickValueTopTransaksi}
                                            tickFormat={this.state.tickFormatTopTransaksi}
                                        />
                                        <VictoryAxis
                                            dependentAxis
                                            tickFormat={(x) => (`${x / 1000}k`)}
                                        />
                                        <VictoryStack>

                                            {
                                                this.state.dataTopTransaksi.map((data) => (
                                                    <VictoryBar
                                                        data={data.data}
                                                        x="quarter"
                                                        y="earnings"
                                                    />
                                                ))
                                            }

                                        </VictoryStack>
                                    </VictoryChart>
                                }
                            </div>
                            {/* <div className="col-1"></div> */}
                        </div>
                    </div>

                </div>
                
            </div>

         );
    }
}
 
export default DashboardOverview;