import React, { Component } from 'react';
import LeftMenu from '../LeftMenu';
import "./DashBoardUserManagemet.css";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from "moment";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CloseRounded from '@material-ui/icons/CloseRounded';

class DashboardUserManagement extends Component {
    state = { 
        columns : [
            {
                label: "USSD",
                key: "ussd",
                name: "ussd",
                sort: true,
            },
            {
                label: "Nama",
                key: "nama",
                name: "nama",
                sort: true
            },
            {
                label: "Join Date",
                key: "created_at",
                name: "created_at",
                sort: true
            },
            {
                label: "Last Activity",
                key: "last_activity_date",
                name: "last_activity_date",
                sort: true
            },
            // {
            //     label: "Nama Toko",
            //     key: "store_id",
            //     name: "store_id",
            //     sort: true
            // },
            {
                label: "Action",
                key: "action"
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedUser : {},
        //all, owner store, cashier
        role: "all",
    }

    options = {
        filterType: 'checkbox',
    };

    loadUsers = (role) => {

        if(role == null){
            role = "all";
        }

        let filter = {};
        if(role !== "all"){
            filter = {role: role};
        }

        const params = {
            filter:filter,
            // sort:{ussd:-1},
            sort:{last_activity_date:-1},
            limit:100
        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .post(
            Api.getUserList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("USERSRESPONSE", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.data.forEach(element => {
                // console.log(element);
                const userData = [
                    element.ussd, 
                    element.fullname, 
                    moment(element.created_at).format("DD-MM-YYYY hh:mm:ss"), 
                    element.last_activity_date !== undefined ? moment(element.last_activity_date).format("DD-MM-YYYY hh:mm:ss") : null,
                    // element.store_id,
                    this.action(element)
                ];
                userDatas.push(userData);
            });

            this.setState({data: userDatas});
            
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

      componentDidMount() {
          this.loadUsers();
      }

      toggleDrawer = (open, userInfo) => (event) => {
          console.log(event);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        this.setState({right: open, selectedUser: userInfo });
      };

      action = (userInfo) => {
          
        return (<span className="setting-button" onClick={this.toggleDrawer(true, userInfo)}>Action v</span>);
    }

    list(anchor,){
        return (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                  <div className="col-11">
                      <h1>Detail Informasi</h1>
                  </div>
                  <div className="col-1">
                      <CloseRounded onClick={this.toggleDrawer(false, {})} />
                  </div>
              </div>
              <br /><br />
                <div className="text-center">
                    <img src="https://picsum.photos/id/1/200/300" className="avatar" alt=""/>
                </div>
              <br />
                <div className="row row-padding">
                    <div className="col-4">Nama</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.fullname}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Id User</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.ussd}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Phone Number</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.ussd}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Tipe Akun</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.role}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4"></div>
                    <div className="col-8 text-right">
                        <strong>Toko Sejati</strong> - Toko Kelontong
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Login Terakhir</div>
                    <div className="col-8 text-right">
                        <strong>{moment(this.state.selectedUser.created_at).format("LLL")}</strong>
                    </div>
                </div>

                <div className="text-center">
                    <br />
                <span className="btn btn-primary btn-rounded">
                    Non Aktifkan
                </span>
                </div>
          </div>
        </div>
      );
    }

    getFilterClasses = (role) => {
        let classes = "col-4";
        if(role === this.state.role){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (role) => {
        console.log("ROLE : ", role);
        this.setState({role: role});
        this.loadUsers(role);
    }

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{})}
                    >
                    {this.list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}

            <div className="container-fluid no-padding">
                <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-3">
                            <h2 className="mt-4">User Management</h2>
                        </div>
                        <div className="col-8">
                            <p className="mt-4">
                            Sebagai halaman monitoring dan manajemen pengelolaan<br />user dari aplikasi<br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid dashboard-content">
                    <div className="row">
                        <div className="col-8">
                            <small>Tampilkan hasil untuk :</small> 
                            <br />
                            {this.state.role === "all" ? "Semua" : this.state.role === "owner store" ? "Pemilik" : "Kasir"}
                        </div>
                        <div className="col-4">
                            <div className="row filter-container">
                                <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                    Semua
                                </div>
                                <div onClick={() => this.handleChangeFilter("owner store")} className={this.getFilterClasses("owner store")}>
                                    Pemilik
                                </div>
                                <div onClick={() => this.handleChangeFilter("cashier")} className={this.getFilterClasses("cashier")}>
                                    Kasir
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <MUIDataTable 
                        title={"Daftar Pengguna Possax"} 
                        data={this.state.data} 
                        columns={this.state.columns} 
                        options={this.options} 
                    />

                </div>
                
            </div>

            </div>

         );

                    

    }
}
 
export default DashboardUserManagement;