class Global {
  _data = [];
  waMessageTemplate = {
      template: {}
  };

  socketData = {
    socket: {}
  };

  add(item) {
    this._data.pop();
    this._data.push(item);
  }

  get() {
    return this._data.length > 0 ? this._data[0] : null;
  }

}

const instance = new Global();
Object.freeze(instance);

export default instance;
