import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import Global from "./lib/Global";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import DashboardOverview from './components/DashboardOverview';
import DashboardUserManagement from './components/DashboardUserManagement';
import DashboardStoreManagement from './components/DashboardStoreManagement';
import DashboardProductsManagement from './components/DashboardProductsManagement';
import DashboardArtikelTutorial from './components/DashboardArtikelTutorial';
import { renderButton, checkSignedIn } from "util";

class App extends Component {
    
    state = {
        auth: {},
        validAuth: false,
        isSignedIn: false
      };
    
      componentDidMount() {
        this.initAppState();
      }

      updateSignin = (signedIn) => { //(3)
        // setIsSignedIn(signedIn);
        this.setState({isSignedIn: signedIn}, () => {
            if (!signedIn) {
                renderButton();
            }
        });
        
      };
    
      initAppState = () => {

        // window.gapi.load("auth2", this.initGoogleSignin);

        let authData = JSON.parse(localStorage.getItem("auth"));
        let validAuth = authData && authData.token && authData.token.token.length > 0;
    
        console.log("AUTHDATA", authData, validAuth);
        Global.add(authData);
    
        this.setState({ auth: authData, validAuth: validAuth });
      };

      initGoogleSignin = () => {
        checkSignedIn()
        .then((signedIn) => {
            this.updateSignin(signedIn);
        })
        .catch((error) => {
            console.error(error);
        });
      }
    
      handleAuthChange = () => {
        this.initAppState();
        // this.props.history.push('/');
      };

    render() { 
        return ( 
            
                this.state.validAuth ? 
                (
                    <Router>

                        <Switch>
                            <Route exact path="/">
                                <Dashboard currentPage="overview" onAuthChange={this.handleAuthChange}>
                                    <DashboardOverview />
                                </Dashboard>
                            </Route>

                            <Route exact path="/user-management">
                                <Dashboard currentPage="user-management" onAuthChange={this.handleAuthChange}>
                                    <DashboardUserManagement />
                                </Dashboard>
                            </Route>

                            <Route exact path="/store-management">
                                <Dashboard currentPage="store-management" onAuthChange={this.handleAuthChange}>
                                    <DashboardStoreManagement />
                                </Dashboard>
                            </Route>

                            <Route exact path="/products-management">
                                <Dashboard currentPage="products-management" onAuthChange={this.handleAuthChange}>
                                    <DashboardProductsManagement />
                                </Dashboard>
                            </Route>
                            <Route exact path="/artikel-tutorial">
                                <Dashboard currentPage="artikel-tutorial" onAuthChange={this.handleAuthChange}>
                                    <DashboardArtikelTutorial />
                                </Dashboard>
                            </Route>
                            {/* <Route path="/setting">
                                <SettingContact />
                            </Route> */}
                        </Switch>
                    </Router>
                ) : 
                (
                    <Login onLogin={this.handleAuthChange} />
                )
            
         );
    }
}
 
export default App;