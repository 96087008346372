import React, { Component } from 'react';
import LeftMenu from '../LeftMenu';
import "./DashBoardProuctsManagemet.css";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import querystring from "querystring";
import App from '../../App';
import moment from "moment";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CloseRounded from '@material-ui/icons/CloseRounded';
import { Box, Link, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import TabContentInputProductManual from "./TabContentInputProductManual";
import TabContentUploadCsv from "./TabContentUploadCsv";

class DashboardProductsManagement extends Component {
    state = { 
        columns : [
            {
                label: "Nama Produk",
                key: "nama",
                name: "nama",
                sort: false
            }, 
            {
                label: "Status",
                key: "status",
                name: "status",
                sort: true
            }, 
            {
                label: "Etalase",
                key: "kategori",
                name: "kategori",
                sort: true
            }, 
            {
                label: "Gambar",
                key: "action",
                name: "foto",
                sort: true
            },
            {
                label: "Action",
                key: "action"
            }
        ],
        data : [], 
        originalData: [],
        right: false,
        selectedProduct : null,
        value : 0,
        files: null,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    options = {
        filterType: 'checkbox',
        customAction: this.action
    };

    loadProducts = () => {
        const params = {
            filter:{store_id: "possax" },
            sort:{nama: 1},
            limit:100
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .post(
            Api.getProductList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("PRODUCTRESPONSE", response.data);
            const responseData = response.data.data;
            
            let displayProducts = [];
            let originalData = [];

            responseData.forEach(element => {
                const etalase = element.etalase;
                element.data.forEach(product => {
                    product.kategori = etalase;
                    originalData.push(product);
                    displayProducts.push([product.nama, product.status, product.kategori, this.renderImage( product.foto.indexOf("http://") !== -1 ? product.foto : Api.getApiUrl() + product.foto), this.action(product)]);
                    // displayProducts.push(product);
                });
            });

            // console.log("DISPLAY : ", displayProducts);
            
            this.setState({data: displayProducts, originalData: originalData});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    componentDidMount() {
        this.loadProducts();
        this.setState({value: 0});
    }
    
    toggleDrawer = (open, productInfo) => (event) => {
        console.log(event);
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({right: open, selectedProduct: productInfo });
    };

    action = (productInfo) => {
      return (<span className="setting-button" onClick={this.toggleDrawer(true, productInfo)}>Setting v</span>);
    }

    renderImage = (imageUrl) => {
        console.log("IMAGEURL : ", imageUrl);
        return (<img className="img-responsive" src={imageUrl} style={{width:50}} alt=""/>);
    }

    handleTabChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    handleSaveFile(files){
        console.log("FILES : ", files);
        this.setState({
            files: files,
        });
    }

    handleCloseForm = () => {
        this.toggleDrawer(false, {});
    }

    handleDataChange = () => {
        this.loadProducts();
    }

    render() { 
        return ( 
            <>
            <div className="container-fluid no-padding">

            {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{})}
                    >
                    <div className="container input-product-container" style={{width: 500}}>
                        <h1 className="mt-4">Tambah Produk</h1>
                        <>
                            <Paper className={this.useStyles.root}>
                                
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab key="tab0" label="Tambah Manual" />
                                    <Tab key="tab1" label="Upload CSV" />
                                </Tabs>
                            </Paper>
                            <TabPanel value={this.state.value} index={0}>
                                <TabContentInputProductManual selectedProduct={this.state.selectedProduct} onDataChange={this.handleDataChange} closeForm={this.handleCloseForm}/>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <TabContentUploadCsv />
                            </TabPanel>
                        </>
                    </div>
                  </Drawer>
                </React.Fragment> 
              ))}

                <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-5">
                            <h1 className="mt-4">Products Management</h1>
                        </div>
                        <div className="col-7 text-right">
                            <br />
                            <span className="mt-4 blue-button" onClick={this.toggleDrawer(true,{})}>Tambah +</span>
                        </div>
                    </div>
                </div>

                <div className="container-fluid dashboard-content">
                    <MUIDataTable 
                        title={"Daftar Produk Possax"}
                        data={this.state.data} 
                        columns={this.state.columns} 
                        options={this.options} 
                    />
                </div>
                
            </div>
            </>
         );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
 
export default DashboardProductsManagement;