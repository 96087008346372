import Global from "./Global";

class Api {

    isProduction = false;
    apiUrl = "http://54.251.169.232:4000/";
    socketUrl = "http://localhost:4001/";


    apiUrlProduction = "http://54.251.169.232:4000/";
    socketUrlProduction = "http://54.251.169.232:4000/";
    // eslint-disable-next-line no-useless-constructor
    constructor() { }

    getRequestHeader() {
        // console.log("GLOBAL : ", Global.get());
        return {
            headers: {
                "Content-Type": `application/json`,
                "Accept": `application/json`,
                // "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTc2YjUyN2FiMGNiMGNlOTUwZGQ2OWYxZWJkOWRmMDU0NGVjYTE4MTQ3MDg0OWY0YmRlZmUxMDkzYjRmNGJiNTcyYzQ0ZDE4Zjk5MWU3ZDIiLCJpYXQiOjE2MDk3Mjc1MDAsIm5iZiI6MTYwOTcyNzUwMCwiZXhwIjoxNjQxMjYzNTAwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.vaBxznomz3Z5_HpiRZ5J_Z3PNeKj57APwx_w66IorcmJVI_bVD1gBodFM_nhX6p6LdSJmkX1Eah64WIBJJiZ__emsN54XLt59BHHvOpSSoYfRKM0NHrpjlXCNzoDaiEY6BVwr74_9rVlR615G5qa_7azUeT2oSiTwUtXWdRUkDDJSq9SxkO_cXms4Zes3WZKSAQmv1NOCUxlbU0NX8Vt3y8bo4oE-eaGktKHua8iyvpABrYITyVmfFJRSu0owe5KzB560oFGLIxJ6ipR3jRO59U-R3mmV7-twIc1mVXY6B96rsyRZX6NFrUAEVyVByGbsjV4Jx4qr3ABN0GzUgLi5HxFS7PvMTTTlYBuB0GEBx5uBFWiH5T1nQLfAmpxSMF0050nyDRk1xH8HNARU3MoadsYwCD6N-81IOz9mm6hh8gXo-d8ZUJdGxi1Iegsh4xPV4ntvEf2CGjBFFZk-GYzgX4yfcilR3Q7pS5N56rukSkBFiipuPRjgtCwXoGZeHB1udWTIyNXLOIcwAR6k27KOP1kvBGy0AMcnxTYJK08VaZGQh6BnrXw3hw_n0GXXFVe0gPUO4IxrHFy0vyuWAFg4xOKdJT0bWykigikvFQM8nkOKvJTZjsbulAyeZo-XGr8FNFeO1WtE0QP7zyd5h6wzuLW7pr_-zc8hAvmJyw-Dbs`,
                // Authorization: "Bearer " + Global.get().access_token,
                "ussd": Global.get().token.ussd,
                "token":Global.get().token.token
            },
        };
    }

    getRequestHeaderNoAuth() {
        return {
            headers: {
                "Content-Type": `application/x-www-form-urlencoded`,
                Accept: `application/json`,
                // "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTc2YjUyN2FiMGNiMGNlOTUwZGQ2OWYxZWJkOWRmMDU0NGVjYTE4MTQ3MDg0OWY0YmRlZmUxMDkzYjRmNGJiNTcyYzQ0ZDE4Zjk5MWU3ZDIiLCJpYXQiOjE2MDk3Mjc1MDAsIm5iZiI6MTYwOTcyNzUwMCwiZXhwIjoxNjQxMjYzNTAwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.vaBxznomz3Z5_HpiRZ5J_Z3PNeKj57APwx_w66IorcmJVI_bVD1gBodFM_nhX6p6LdSJmkX1Eah64WIBJJiZ__emsN54XLt59BHHvOpSSoYfRKM0NHrpjlXCNzoDaiEY6BVwr74_9rVlR615G5qa_7azUeT2oSiTwUtXWdRUkDDJSq9SxkO_cXms4Zes3WZKSAQmv1NOCUxlbU0NX8Vt3y8bo4oE-eaGktKHua8iyvpABrYITyVmfFJRSu0owe5KzB560oFGLIxJ6ipR3jRO59U-R3mmV7-twIc1mVXY6B96rsyRZX6NFrUAEVyVByGbsjV4Jx4qr3ABN0GzUgLi5HxFS7PvMTTTlYBuB0GEBx5uBFWiH5T1nQLfAmpxSMF0050nyDRk1xH8HNARU3MoadsYwCD6N-81IOz9mm6hh8gXo-d8ZUJdGxi1Iegsh4xPV4ntvEf2CGjBFFZk-GYzgX4yfcilR3Q7pS5N56rukSkBFiipuPRjgtCwXoGZeHB1udWTIyNXLOIcwAR6k27KOP1kvBGy0AMcnxTYJK08VaZGQh6BnrXw3hw_n0GXXFVe0gPUO4IxrHFy0vyuWAFg4xOKdJT0bWykigikvFQM8nkOKvJTZjsbulAyeZo-XGr8FNFeO1WtE0QP7zyd5h6wzuLW7pr_-zc8hAvmJyw-Dbs`,
                // "Authorization": 'Bearer ' + Global.get().access_token,
            },
        };
    }

    getApiUrl() {
        return this.isProduction ? this.apiUrlProduction : this.apiUrl;
    }

    login() {
        return this.getApiUrl() + "loginAdmin";
    }

    getProductList(){
        return this.getApiUrl() + "item/list"
    }

    getUserList(){
        return this.getApiUrl() + "user/list"
    }

    getStoreList(){
        return this.getApiUrl() + "store/list"
    }

    getProductCategoryList(){
        return this.getApiUrl() + "items/category"
    }

    uploadImage(){
        return this.getApiUrl() + "uploadImage"
    }

    addProduct(){
        return this.getApiUrl() + "item"
    }

    topStatistics(){
        return this.getApiUrl() + "topStatistics"
    }

    topStores(){
        return this.getApiUrl() + "admin/listStores"
    }
    
    listArtikel(){
        return this.getApiUrl() + "article/list"
    }

    saveArtikel(){
        return this.getApiUrl() + "article"
    }
}

const instance = new Api();
Object.freeze(instance);

export default instance;
