import React, { Component } from 'react';
import LeftMenu from '../LeftMenu';
import "./DashBoardStoreManagement.css";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";

class DashboardStoreManagement extends Component {
    state = { 
        columns : [
            {
                label: "Lokasi",
                key: "lokasi",
                name: "alamat",
                sort: true
            },
            {
                label: "Nama Toko",
                key: "nama_toko",
                name: "nama_toko",
                sort: true,
            },
            {
                label: "Jumlah Produk",
                key: "jumlah_produk",
                name: "jumlah_produk",
                sort: true,
            },
            {
                label: "Transaksi",
                key: "transaksi",
                name: "transaksi",
                sort: true,
            },
            {
                label:"Action",
                key: "ation",
            }
        ],
        data : [
            // ["Joe James", "Test Corp", "Yonkers", "NY"],
            // ["John Walsh", "Test Corp", "Hartford", "CT"],
            // ["Bob Herm", "Test Corp", "Tampa", "FL"],
            // ["James Houston", "Test Corp", "Dallas", "TX"],
        ], 
        //all, has transaction, no transaction
        filter: "all"
    }

    options = {
        filterType: 'checkbox',
    };

    loadUsers = () => {
        const params = {
            filter:{},
            sort:{},
            limit:100
        };
    
        console.log("PARAMS STORES", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .post(
            Api.getStoreList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("STORERESPONSE", response.data);
            const responseData = response.data;
            const userDatas = [...this.state.data];
            
            responseData.data.forEach(element => {
                // console.log(element);
                const userData = [element.alamat, element.nama_toko, 0, 0, this.action(element)];
                userDatas.push(userData);
            });

            this.setState({data: userDatas});
            
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

      componentDidMount() {
          this.loadUsers();
      }

      handleChangeFilter = (filter) => {
          this.setState({filter: filter});
      }

      getFilterClasses = (filter) => {
        let classes = "col-4";
        if(filter === this.state.filter){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    action = (storeInfo) => {
        return (<span className="setting-button">Setting v</span>);
      }

    render() { 
        return ( 

            <div className="container-fluid no-padding">
                <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-3">
                            <h3 className="mt-4">Store Management</h3>
                        </div>
                        <div className="col-8">
                            <p className="mt-4">
                            Sebagai halaman monitoring dan manajemen pengelolaan<br />user dari aplikasi<br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid dashboard-content">
                    <div className="row">
                        <div className="col-6">
                            <small>Tampilkan hasil untuk :</small> 
                            <br />
                            {this.state.filter === "all" ? "Semua" : this.state.filter === "has transaction" ? "Sudah ada transaksi" : "Belum ada transaksi"}
                        </div>
                        <div className="col-6">
                            <div className="row filter-container">
                                <div className={this.getFilterClasses("all")} onClick={() => this.handleChangeFilter("all")}>
                                    Semua
                                </div>
                                <div className={this.getFilterClasses("has transaction")} onClick={() => this.handleChangeFilter("has transaction")}>
                                    Sudah ada Transaksi
                                </div>
                                <div className={this.getFilterClasses("no transaction")} onClick={() => this.handleChangeFilter("no transaction")}>
                                    Belum ada Transaksi
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <MUIDataTable 
                        title={"Employee List"} 
                        data={this.state.data} 
                        columns={this.state.columns} 
                        options={this.options} 
                    />
                </div>
                
            </div>

         );
    }
}
 
export default DashboardStoreManagement;