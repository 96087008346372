import React, { Component } from 'react';
import {FaGem, FaHeart} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import SideMenuLogo from "../../assets/image/side-menu-logo.png";
import "./LeftMenu.css";
// import "./LeftMenu.scss"

class LeftMenu extends Component {
    state = {  }

    handleLogout = () => {
        localStorage.removeItem("auth");
        this.props.onAuthChange();
    };

    render() { 
        console.log("CURRENT_PAGE : ", this.props.currentPage);
        return ( 
            <div className="border-right" id="sidebar-wrapper">
                <div className="sidebar-heading">
                    <img className="" src={SideMenuLogo} />
                </div>
                <div className="list-group list-group-flush">
                    <Link className={this.getItemMenuClasses("overview")} to="/">Overview</Link>
                    <Link className={this.getItemMenuClasses("user-management")} to="/user-management">User Management</Link>
                    <Link className={this.getItemMenuClasses("store-management")} to="/store-management">Store Management</Link>
                    <Link className={this.getItemMenuClasses("products-management")} to="/products-management">Products Management</Link>
                    <Link className={this.getItemMenuClasses("artikel-tutorial")} to="/artikel-tutorial">Artikel & Tutorial</Link>
                    <div onClick={this.handleLogout} className={this.getItemMenuClasses("products-management")} >Logout</div>
                </div>
            </div>
         );
    }

    getItemMenuClasses(page){
        let classes = "list-group-item list-group-item-action";
        classes += page === this.props.currentPage ? " selected" : "";
        console.log("CLASSES : ", classes);
        return classes;
    }

}
 
export default LeftMenu;