import React, { Component } from 'react';
import LeftMenu from '../LeftMenu';
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import "./Dashboard.css";


class Dashboard extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="d-flex" id="wrapper">

                <LeftMenu onAuthChange={this.props.onAuthChange} currentPage={this.props.currentPage} />
                
                <div id="page-content-wrapper">
                    { this.props.children }
                </div>

            </div>
         );
    }
}
 
export default Dashboard;